import axios from "axios";
import router from '../../../router'

import {
    networkErrorMessage,
    serverErrorMessage,
    appErrorMessage
} from './systemMessages.service';

const handdleError = (error) => {
    let serverError = new Error();
    serverError.handdled = true;

    if (!error.response) networkErrorMessage();
    else if (error.response.status == 401) {
        serverError.message = 'Sesión no válida';
        serverError.operational = true;
    }
    else if (error.response.status == 422) {
        serverError.message = 'No se encontrarón datos con estos filtros.';
        serverError.operational = true;
    } else {
        if (error.response.status == 500) serverErrorMessage();
        else appErrorMessage();
    }
    throw serverError;
}

const validateResponse = (response) => {
    if (response.noAuth) {
        localStorage.removeItem("agnus-token");
        router.push('/login');
    }
}

export async function getServerRequest(url, config) {
    try {
        const response = await axios
            .get(url, config)
        validateResponse(response.data);
        return response.data;
    } catch (error) {
        handdleError(error);
    }
}

export async function postServerRequest(url, data, config) {
    try {
        const response = await axios
            .post(url, data, config)
        validateResponse(response.data);
        return response.data;
    } catch (error) {
        handdleError(error);
    }
}

export async function putServerRequest(url, data, config) {
    try {
        const response = await axios
            .put(url, data, config)
        validateResponse(response.data);
        return response.data;
    } catch (error) {
        handdleError(error);
    }
}

export async function patchServerRequest(url, data, config) {
    try {
        const response = await axios
            .patch(url, data, config)
        validateResponse(response.data);
        return response.data;
    } catch (error) {
        handdleError(error);
    }
}

export async function deleteServerRequest(url, config) {
    try {
        const response = await axios
            .delete(url, config)
        validateResponse(response.data);
        return response.data;
    } catch (error) {
        handdleError(error);
    }
}